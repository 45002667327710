<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-back" @click="goBack()">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="arrow-left-circle" transform="translate(-1 -1)">
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="10" cy="10" r="10" transform="translate(2 2)" fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_238" data-name="Path 238" d="M12,8,8,12l4,4" fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <line id="Line_128" data-name="Line 128" x1="8" transform="translate(8 12)" fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>
                Back to Leads
            </div>
        </div>

        <div class="page_content">
            <div class="lead_info">
                <div class="lead_info-edit" @click="leadInfoEdit()" id="lead_edit-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.995" height="22" viewBox="0 0 21.995 22">
                        <g id="Group_8224" data-name="Group 8224" transform="translate(-2423.299 -672.156)">
                            <path id="Path_299" data-name="Path 299" d="M2468.087,736.132l-4.73-4.741,9.57-9.56,4.735,4.742Z" transform="translate(-36.62 -45.413)" fill="#bbcbd1"/>
                            <path id="Path_300" data-name="Path 300" d="M2596.548,674.519c.643-.661,1.3-1.394,2.022-2.059a1.2,1.2,0,0,1,1.617.021q1.612,1.535,3.151,3.147a1.228,1.228,0,0,1,0,1.687c-.653.7-1.364,1.336-2.027,1.978Z" transform="translate(-158.381 0)" fill="#bbcbd1"/>
                            <path id="Path_301" data-name="Path 301" d="M2429.471,861.188l-6.172,1.425,1.422-6.186Z" transform="translate(0 -168.457)" fill="#bbcbd1"/>
                        </g>
                    </svg>
                </div>
                <div class="lead_info-head">
                    <div class="lead_info-head-img"></div>

                    <div class="lead_info-data" >
                        <div class="lead_info-head-name">
                           {{leadProfileInfo.name}}
                        </div>

                        <div v-if="leadProfileInfo.status"
                             class="item_status"
                             :style="{border: `1px solid ${leadProfileInfo.status.color}`, color: `${leadProfileInfo.status.color}`}"
                        >
                            <b-form-select v-model="leadProfileInfo.status.id" @change="changeColor">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Select Status</b-form-select-option>
                                </template>
                                <option
                                        v-for="(option, id) in allStatuses"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                        :style="{color: option.color}"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                        </div>

                        <div class="item_status"
                             v-else
                             :style="{border: `1px solid black`, color: `black`}"
                        >
                            <b-form-select  @change="changeColor">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Select Status</b-form-select-option>
                                </template>
                                <option
                                        v-for="(option, id) in allStatuses"
                                        :key="id"
                                        :value="option.id"
                                        :title="option.name || null"
                                        :style="{color: option.color}"
                                >
                                    {{option.name}}
                                </option>
                            </b-form-select>
                        </div>

                        <div class="lead_info-reply">
<!--                            <span>Last Reply On:</span> {{getDate(leadProfileInfo.updated_at)}}-->
                        </div>
                    </div>

                </div>
                <div class="lead_info-personal">
                    <div class="lead_info-personal-title">Personal Information</div>
                    <div class="info_item">
                        <div class="info_item-key">Type:</div>
                        <div class="info_item-value">{{getType(leadProfileInfo.lead_type)}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Phone:</div>
                        <div class="info_item-value">{{leadProfileInfo.phone}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Email:</div>
                        <div class="info_item-value">{{leadProfileInfo.email}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Address:</div>
                        <div class="info_item-value">{{leadProfileInfo.address}}</div>
                    </div>
<!--                    <div class="info_item">-->
<!--                        <div class="info_item-key">Street:</div>-->
<!--                        <div class="info_item-value">{{leadProfileInfo.street}}</div>-->
<!--                    </div>-->
                    <div class="info_item">
                        <div class="info_item-key">City:</div>
                        <div class="info_item-value">{{leadProfileInfo.city}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">State:</div>
                        <div class="info_item-value">{{leadProfileInfo.state}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">ZIP:</div>
                        <div class="info_item-value">{{leadProfileInfo.zip}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Selling in:</div>
                        <div class="info_item-value">{{getSellingIn(leadProfileInfo.selling_in)}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Appraisal:</div>
                        <div class="info_item-value">{{leadProfileInfo.appraisal}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Beds:</div>
                        <div class="info_item-value">{{leadProfileInfo.beds}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Bath:</div>
                        <div class="info_item-value">{{leadProfileInfo.bath}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Buying in</div>
                        <div class="info_item-value">{{getSellingIn(leadProfileInfo.buying_in)}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Price Range:</div>
                        <div class="info_item-value">$ {{leadProfileInfo.price_range_min || 0}} -  $ {{leadProfileInfo.price_range_max || 0}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Neighborhoods:</div>
                        <div class="info_item-value">{{leadProfileInfo.neighborhoods}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Prequalified:</div>
                        <div class="info_item-value">{{leadProfileInfo.pre_qualify}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Lender:</div>
                        <div class="info_item-value">{{leadProfileInfo.lender}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Lender Email:</div>
                        <div class="info_item-value">{{leadProfileInfo.lender_email}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Lender Phone:</div>
                        <div class="info_item-value">{{leadProfileInfo.lender_phone}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Credit:</div>
                        <div class="info_item-value">{{leadProfileInfo.credit}}</div>
                    </div>
                </div>
                <div class="lead_info-personal no_border">
                    <div class="lead_info-personal-title">Other Information</div>
                    <div class="info_item">
                        <div class="info_item-key">Source:</div>
                        <div class="info_item-value">{{leadProfileInfo.source}}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Funnel:</div>
                        <div class="info_item-value" v-if="leadProfileInfo.funnels">
                            <div class="mr-1" v-for="item in leadProfileInfo.funnels" v-if="item !== null">
                                {{item.funnel_name}},
                            </div>
                        </div>
                    </div>
                    <div class="info_item">
                        <div class="info_item-key">Last Activity:</div>
                        <div class="info_item-value">{{getDate(leadProfileInfo.updated_at)}}</div>
                    </div>
                </div>
            </div>
            <div class="lead_notes">
                <div class="lead_notes-container">
                    <div>
                        <b-card no-body>
                            <b-tabs pills >

                                <b-tab title="Notes" active>
                                    <b-card-text>
                                        <div class="add_notes-content">
                                            <b-form-textarea
                                                    id="textarea"
                                                    v-model="addNoteData.description"
                                                    placeholder="Add Notes"
                                                    rows="7"
                                                    max-rows="7"
                                            >
                                            </b-form-textarea>
                                            <span v-if="notesErrors['description']" class="text_danger mt-1">{{notesErrors['description'][0]}}</span>


                                            <div class="tab_bottom-btn-container">
                                                <b-button class="tab_bottom-btn" @click="addNote()">Create a Note</b-button>
                                            </div>
                                        </div>
                                    </b-card-text>

                                    <div class="lead_history-container">
                                        <div class="history_title">
                                            History
                                        </div>
                                        <div class="history_content" v-if="notes_data.length">
                                            <div class="history_item" v-for="item in notes_data">
                                                <div class="history_item-content">
                                                    <div class="history_time-box">
                                                        <div class="history_time-box_date">{{getDateMonth(item.updated_at)}}</div>
                                                        <div class="history_time-box_time">{{getTime(item.updated_at)}}</div>
                                                    </div>
                                                    <div class="history_text">
                                                        <div class="history_text-title">Note</div>
                                                        <div class="history_text-txt">{{item.description}}</div>
                                                    </div>
                                                </div>
                                                <div class="history_like" :class="{'full_heart': item.liked}" @click="likeHistory(item)">
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="empty_data">No notes yet...</div>
                                    </div>

                                </b-tab>

<!--                                <b-tab title="Send Email">-->
<!--                                    <b-card-text>-->
<!--                                        <div class="send_email-content">-->
<!--                                            <div class="send_email-info">-->
<!--                                                <div class="subject_content">-->
<!--                                                    <div class="subject_text">Subject:</div>-->
<!--                                                        <input type="email" v-model="sendEmailData.email" placeholder="Enter Subject Line" />-->
<!--                                                </div>-->
<!--                                                <b-form-textarea-->
<!--                                                        placeholder="Type your message here.."-->
<!--                                                        rows="6"-->
<!--                                                        max-rows="6"-->
<!--                                                        v-model="sendEmailData.sendText"-->
<!--                                                >-->
<!--                                                </b-form-textarea>-->

<!--                                                <div class="file_attach-container">-->
<!--                                                    <div class="file_attach">-->
<!--                                                        <b-form-file-->
<!--                                                                v-model="sendEmailData.attach_file"-->
<!--                                                                placeholder="Attachment"-->
<!--                                                                multiple-->
<!--                                                        ></b-form-file>-->
<!--                                                    </div>-->
<!--                                                </div>-->


<!--                                            </div>-->
<!--                                            <div class="tab_bottom-btn-container">-->
<!--                                                <div class="input_item">-->
<!--                                                    <div class="select_arrow">-->
<!--                                                        <b-form-select >-->
<!--                                                            <template #first>-->
<!--                                                                <b-form-select-option :value="null" disabled>Select</b-form-select-option>-->
<!--                                                            </template>-->
<!--                                                            <option-->
<!--                                                                    v-for="(option, id) in 5"-->
<!--                                                                    :key="id"-->
<!--                                                                    :value="option"-->
<!--                                                                    :title="option || null"-->
<!--                                                            >-->
<!--                                                                {{option}}-->
<!--                                                            </option>-->
<!--                                                        </b-form-select>-->
<!--                                                        <div class="arrow_down"></div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                                <b-button class="tab_bottom-btn" @click="sendEmail()">Send Email</b-button>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </b-card-text>-->
<!--                                </b-tab>-->

<!--                                <b-tab title="Send Text">-->
<!--                                    <b-card-text>-->
<!--                                        <div class="send_text-content">-->
<!--                                            <div class="send_text-info">-->
<!--                                                <b-form-textarea-->
<!--                                                        id="textarea"-->
<!--                                                        v-model="sendTextData.text"-->
<!--                                                        placeholder="Type your message here.."-->
<!--                                                        rows="7"-->
<!--                                                        max-rows="7"-->
<!--                                                >-->
<!--                                                </b-form-textarea>-->

<!--                                            </div>-->
<!--                                            <div class="tab_bottom-btn-container">-->
<!--                                                <div class="input_item">-->
<!--                                                    <div class="select_arrow">-->
<!--                                                        <b-form-select >-->
<!--                                                            <template #first>-->
<!--                                                                <b-form-select-option :value="null" disabled>Select</b-form-select-option>-->
<!--                                                            </template>-->
<!--                                                            <option-->
<!--                                                                    v-for="(option, id) in 5"-->
<!--                                                                    :key="id"-->
<!--                                                                    :value="option"-->
<!--                                                                    :title="option || null"-->
<!--                                                            >-->
<!--                                                                {{option}}-->
<!--                                                            </option>-->
<!--                                                        </b-form-select>-->
<!--                                                        <div class="arrow_down"></div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                                <b-button class="tab_bottom-btn" @click="createNote()">Create a Note</b-button>-->
<!--                                            </div>-->
<!--                                        </div>-->

<!--                                    </b-card-text>-->
<!--                                </b-tab>-->

                            </b-tabs>
                        </b-card>
                    </div>
                </div>

            </div>
<!--Lead edit modal start-->
            <b-modal ref="lead_edit-modal" centered hide-header hide-footer hide-header-close >
                <div class="lead_modal_content">
                    <b-icon icon="x" class="x_btn" aria-hidden="true" @click="editLeadModal()"></b-icon>
                    <div class="d-flex">
                        <div class="lead_title">
                            LEAD EDIT
                        </div>
                    </div>

                    <div class="input_content">
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Name</label>
                                <b-form-input
                                        placeholder="e.g. John Smith"
                                        v-model="edit_lead_data.name"
                                        type="text"
                                        trim
                                ></b-form-input>
                                <span v-if="editLeadErrors['name']" class="text_danger mt-1">{{editLeadErrors['name'][0]}}</span>
                            </div>
                            <div class="input_item">
                                <label>Phone (10 digits)</label>
                                <b-form-input
                                        placeholder="000-000-0000"
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        v-model="edit_lead_data.phone"
                                        onKeyPress="if(this.value.length==10) return false;"
                                        type="number"
                                        trim
                                ></b-form-input>
                                <span v-if="editLeadErrors['phone']" class="text_danger mt-1">{{editLeadErrors['phone'][0]}}</span>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Email</label>
                                <b-form-input
                                        placeholder="Email..."
                                        v-model="edit_lead_data.email"
                                        type="email"
                                        trim
                                ></b-form-input>
                                <span v-if="editLeadErrors['email']" class="text_danger mt-1">{{editLeadErrors['email'][0]}}</span>
                            </div>
                            <div class="input_item">
                                <label>Type</label>
                                <div class="select_arrow">
                                    <b-form-select v-model="edit_lead_data.lead_type">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                        </template>
                                        <option
                                                v-for="(option, id) in typeOptions"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.title || null"
                                        >
                                            {{option.title}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                    <span v-if="editLeadErrors['lead_type']" class="text_danger mt-1">{{editLeadErrors['lead_type'][0]}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="single_input">
                            <div class="input_item">
                                <label>Address</label>
                                <b-form-input
                                        placeholder="Address"
                                        v-model="edit_lead_data.address"
                                        type="text"
                                        trim
                                ></b-form-input>
                                <span v-if="editLeadErrors['address']" class="text_danger mt-1">{{editLeadErrors['address'][0]}}</span>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>City</label>
                                <b-form-input
                                        placeholder="City"
                                        v-model="edit_lead_data.city"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>State</label>
                                <b-form-input
                                        placeholder="State"
                                        v-model="edit_lead_data.state"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Zip</label>
                                <b-form-input
                                        placeholder="Zip"
                                        v-model="edit_lead_data.zip"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Selling in</label>
                                <div class="select_arrow">
                                    <b-form-select v-model="edit_lead_data.selling_in">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                        </template>
                                        <option
                                                v-for="(option, id) in sellingIn"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.title || null"
                                        >
                                            {{option.title}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                    <span v-if="editLeadErrors['selling_in']" class="text_danger mt-1">{{editLeadErrors['selling_in'][0]}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Appraisal</label>
                                <b-form-input
                                        placeholder="Appraisal"
                                        v-model="edit_lead_data.appraisal"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Beds</label>
                                <b-form-input
                                        placeholder="Beds"
                                        v-model="edit_lead_data.beds"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Bath</label>
                                <b-form-input
                                        placeholder="Bath"
                                        v-model="edit_lead_data.bath"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Buying in</label>
                                <div class="select_arrow">
                                    <b-form-select v-model="edit_lead_data.buying_in">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                                        </template>
                                        <option
                                                v-for="(option, id) in sellingIn"
                                                :key="id"
                                                :value="option.id"
                                                :title="option.title || null"
                                        >
                                            {{option.title}}
                                        </option>
                                    </b-form-select>
                                    <div class="arrow_down"></div>
                                </div>
                                <span v-if="editLeadErrors['buying_in']" class="text_danger mt-1">{{editLeadErrors['buying_in'][0]}}</span>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Price Range (min)</label>
                                <b-form-input
                                        placeholder="Price Range (min)"
                                        v-model="edit_lead_data.price_range_min"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Price Range (max)</label>
                                <b-form-input
                                        placeholder="BPrice Range (max)"
                                        v-model="edit_lead_data.price_range_max"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Neighborhoods</label>
                                <b-form-input
                                        placeholder="Neighborhoods"
                                        v-model="edit_lead_data.neighborhoods"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Prequalified</label>
                                <b-form-input
                                        placeholder="Prequalified"
                                        v-model="edit_lead_data.pre_qualify"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Lender</label>
                                <b-form-input
                                        placeholder="Lender"
                                        v-model="edit_lead_data.lender"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Lender Email</label>
                                <b-form-input
                                        placeholder="Lender Email"
                                        v-model="edit_lead_data.lender_email"
                                        type="email"
                                        trim
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Lender Phone</label>
                                <b-form-input
                                        placeholder="Lender Phone"
                                        v-model="edit_lead_data.lender_phone"
                                        type="number"
                                        trim
                                ></b-form-input>
                            </div>
                            <div class="input_item">
                                <label>Credit</label>
                                <b-form-input
                                        placeholder="Credit"
                                        v-model="edit_lead_data.credit"
                                        type="text"
                                        trim
                                ></b-form-input>
                            </div>
                        </div>
                        <div class="single_input">
                            <div class="input_item funnel">
                                <label>Funnel</label>
                                <div class="select_arrow">
                                    <Multiselect
                                            v-model="chosen_funnels"
                                            :options="all_funnels"
                                            label="funnel_name"
                                            track-by="id"
                                            :close-on-select="false"
                                            :hide-selected="true"
                                            @remove="removeOption($event)"
                                            @select="selectOption($event)"
                                            multiple
                                    ></Multiselect>
                                    <div class="arrow_down"></div>
                                </div>
                                <!--                                <b-form-input-->
                                <!--                                        placeholder="Funnel"-->
                                <!--                                        v-model="edit_lead_data.funnel"-->
                                <!--                                        type="text"-->
                                <!--                                        trim-->
                                <!--                                ></b-form-input>-->
                            </div>
                        </div>
                        <div class="dual_inputs">
                            <div class="input_item">
                                <label>Source</label>
                                <b-form-input
                                        placeholder="Source"
                                        v-model="edit_lead_data.source"
                                        type="text"
                                        trim
                                ></b-form-input>
                                <span v-if="editLeadErrors['source']" class="text_danger mt-1">{{editLeadErrors['source'][0]}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="done_btn-container">
                        <b-button class="done_btn" @click="editLead()">Save Changes</b-button>
                    </div>
                </div>
            </b-modal>
<!--Lead edit modal end-->
        </div>



    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "LeadsProfile",
        data() {
            return {
                borderPosition: true,
                itemId: '',
                allStatuses: [],
                leadProfileInfo: {},
                leadStatus: 2,
                notes_data: [],
                addNoteData: {
                    description: '',
                    lead_id: null
                },
                sendEmailData: {
                    email: '',
                    sendText: '',
                    attach_file: []
                },
                notesErrors: {},
                sendTextData: {
                    text: ''
                },
                typeOptions: [
                    {
                        title: 'Seller',
                        id: 1
                    },
                    {
                        title: 'Buyer',
                        id: 0
                    },
                    {
                        title: 'All Leads',
                        id: 2
                    }
                ],
                sellingIn: [
                    {
                        id: 0,
                        title: '1-3 months'
                    },
                    {
                        id: 1,
                        title: '3-6 months'
                    },
                    {
                        id: 2,
                        title: '6-12 months'
                    },
                    {
                        id: 3,
                        title: 'Not Sure'
                    }
                ],
                edit_lead_data: {
                    name: null,
                    phone: null,
                    email: null,
                    lead_type: null,
                    address: null,
                    city: null,
                    state: null,
                    zip: null,
                    selling_in: null,
                    appraisal: null,
                    beds: null,
                    bath: null,
                    buying_in: null,
                    price_range_min: null,
                    price_range_max: null,
                    neighborhoods: null,
                    pre_qualify: null,
                    lender: null,
                    lender_email: null,
                    lender_phone: null,
                    credit: null,
                    source: null,
                    lead_id: null,
                    funnels_id: [],
                    funnel_delete_id: []
                    // user_id: 8
                },
                all_funnels: [],
                chosen_funnels: [],
                editLeadErrors: []
            }
        },
        created() {
            alert.$on("clickOn",border =>{
                this.borderPosition = border;
            })
            this.itemId = this.$route.params.id
            this.getAllStatuses()
                .then(res => {
                    if(res.status === 200) {
                        this.allStatuses = res.data.success
                    }
                })
            this.getLeadData(this.itemId)
                .then(res => {
                    this.leadProfileInfo = res.data.success
                })
            this.readNotes(this.itemId)
                .then(res => {
                    if(res.status === 200) {
                        this.notes_data = res.data.success
                    }
                })
            this.getAllFunnels()
                .then(res => {
                    if(res.status === 200) {
                        this.all_funnels = res.data.success
                    }
                })
        },
        computed: {
          // toDay() {
          //     return new Date(Date.now()).toLocaleString('default', { month: 'long' }) +' '+  new Date(Date.now()).getFullYear()
          // }
        },
        methods: {
            ...mapActions([
                'getAllStatuses',
                'getAllFunnels',
                'getLeadData',
                'leadEdit',
                'editLeadStatus',
                'readNotes',
                'noteAdd'
            ]),

            goBack() {
                this.$router.go(-1)
            },
            getDate(item) {
                return new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
            },
            getDateMonth(date) {
                return new Date(date).toLocaleString('default', { month: 'short' , day: 'numeric'})
            },
            getTime(date) {
                return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            },
            getType(type) {
                console.log(type, 'hhhhhh')
                if(type === '0') {
                    return 'Buyer'
                } else if(type === '1') {
                    return 'Seller'
                } else {
                    return  'All Leads'
                }
            },
            getSellingIn(args) {
                switch (args) {
                    case 0: return '1-3 months';
                    case 1: return '3-6 months';
                    case 2: return '6-12 months';
                    case 3: return 'Not Sure';
                }
            },
            getStatusData(id) {
                // console.log(id, 'status')
            },
            changeColor(args) {
                let statusData = {
                    lead_id: this.leadProfileInfo.id,
                    status_id: args
                }
                this.editLeadStatus(statusData)
                    .then(res => {
                        if(res.status === 200) {
                            this.getLeadData(this.itemId)
                                .then(res => {
                                    this.leadProfileInfo = res.data.success
                                })
                        }
                    })

            },
            likeHistory(item) {
                item.liked = !item.liked
            },
            addNote() {
                this.notesErrors = {}
                this.addNoteData.lead_id = this.itemId
                this.noteAdd(this.addNoteData)
                .then(res => {
                    if(res.status === 200) {
                        this.addNoteData.description = ''
                        this.readNotes(this.itemId)
                            .then(res => {
                                if(res.status === 200) {
                                    this.notes_data = res.data.success
                                }
                            })
                    } else {
                        this.notesErrors = res.response.data.errors
                    }
                })
            },
            sendEmail() {
                console.log(this.sendEmailData, 'send email')
            },
            createNote() {
                console.log(this.sendTextData)
            },
            leadInfoEdit() {
                this.edit_lead_data.name = this.leadProfileInfo.name
                this.edit_lead_data.phone = this.leadProfileInfo.phone
                this.edit_lead_data.email = this.leadProfileInfo.email
                this.edit_lead_data.lead_type = this.leadProfileInfo.lead_type
                this.edit_lead_data.address = this.leadProfileInfo.address
                // this.edit_lead_data.street = this.leadProfileInfo.street
                this.edit_lead_data.city = this.leadProfileInfo.city
                this.edit_lead_data.state = this.leadProfileInfo.state
                this.edit_lead_data.zip = this.leadProfileInfo.zip
                this.edit_lead_data.selling_in = this.leadProfileInfo.selling_in
                this.edit_lead_data.appraisal = this.leadProfileInfo.appraisal
                this.edit_lead_data.beds = this.leadProfileInfo.beds
                this.edit_lead_data.bath = this.leadProfileInfo.bath
                this.edit_lead_data.buying_in = this.leadProfileInfo.buying_in
                this.edit_lead_data.price_range_min = this.leadProfileInfo.price_range_min
                this.edit_lead_data.price_range_max = this.leadProfileInfo.price_range_max
                this.edit_lead_data.neighborhoods = this.leadProfileInfo.neighborhoods
                this.edit_lead_data.pre_qualify = this.leadProfileInfo.pre_qualify
                this.edit_lead_data.lender = this.leadProfileInfo.lender
                this.edit_lead_data.lender_email = this.leadProfileInfo.lender_email
                this.edit_lead_data.lender_phone = this.leadProfileInfo.lender_phone
                this.edit_lead_data.credit = this.leadProfileInfo.credit
                this.edit_lead_data.source = this.leadProfileInfo.source
                this.edit_lead_data.lead_id = this.leadProfileInfo.id
                this.chosen_funnels = this.leadProfileInfo.funnels
                this.edit_lead_data.funnel_delete_id = []
                this.edit_lead_data.funnels_id = []
                this.editLeadModal()
            },
            editLeadModal() {
                this.$refs['lead_edit-modal'].toggle('#lead_edit-btn')
            },
            selectOption(item) {
                console.log(item, 'ggg')
                this.edit_lead_data.funnels_id.push(item.id)
            },
            removeOption(removedOption) {
                this.edit_lead_data.funnel_delete_id.push(removedOption.id)
            },
            editLead() {
                this.editLeadErrors = []
                // console.log(this.chosen_funnels,this.leadProfileInfo.funnels, 'dsfsdfsdfsdfsfd');
                // let result1 = this.chosen_funnels.filter(o1 => this.leadProfileInfo.funnels.some(o2 => o1.id !== o2.id));
                // console.log(result1, 'result creat id')
                // let result2 = this.leadProfileInfo.funnels.filter(o1 => this.chosen_funnels.some(o2 => o1.id !== o2.id));
                // console.log(result2, 'del m')

                // let result3 = this.chosen_funnels.filter(o1 => this.leadProfileInfo.funnels.some(o2 => o1.id === o2.id));
                // console.log(result3, 'del m')

                // if(this.chosen_funnels.length) {
                //     this.chosen_funnels.forEach(item => {
                //
                //         this.edit_lead_data.funnels_id.push(item.id)
                //     })
                // }
                // this.leadProfileInfo.funnels.forEach(funnel => {
                //     this.chosen_funnels.forEach(item => {
                //         if(funnel.id !== item.id) {
                //             this.edit_lead_data.funnel_delete_id.push(funnel.id)
                //         }
                //     })
                // })
                // this.chosen_funnels.forEach(item => {
                //     this.edit_lead_data.funnels_id.push(item.id)
                // })

                // this.chosen_funnels.forEach(item => {
                //     this.leadProfileInfo.funnels.forEach(funnel => {
                //         console.log(funnel, 'funnel')
                //         if(item.id !== funnel.id) {
                //             this.edit_lead_data.funnels_id.push(item.id)
                //         }
                //     })
                // })
                // let result = this.leadProfileInfo.funnels.filter(o1 => this.chosen_funnels.some(o2 => o1.id === o2.id));
                // this.leadProfileInfo.funnels.forEach(funnel => {
                //     this.chosen_funnels.forEach(item => {
                //         if(item.id !== funnel.id) {
                //             this.edit_lead_data.funnel_delete_id.push(funnel.id)
                //         } else {
                //             this.edit_lead_data.funnels_id.push(item.id)
                //         }
                //     })
                // })
                this.leadEdit(this.edit_lead_data)
                .then(res => {
                    if(res.status === 200) {
                        this.editLeadModal()
                        this.getLeadData(this.itemId)
                            .then(res => {
                                this.leadProfileInfo = res.data.success
                            })
                    } else {
                        this.editLeadErrors = res.response.data.errors
                    }
                })
            }

        }
    }
</script>

<style scoped lang="scss">
    /* lead edit modal*/
    .lead {
        &_modal_content {
            position: relative;
            padding: 0 20px;
            width: 560px;
            & .x_btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 25px;
                height: 25px;
                cursor: pointer;
                color: #B4BABC;
            }
            & .input_content {
                display: flex;
                flex-direction: column;
                & .dual_inputs {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    &-switch {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        .input_item-switch {
                            padding: 5px 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            & label {
                                color: $black;
                                font-weight: 500;
                                font-size: 16px;
                            }
                        }
                        .input_item {
                            padding: 5px 0;
                            width: 374px;
                            & .select_arrow {
                                position: relative;
                                & .arrow_down {
                                    position: absolute;
                                    top: 14px;
                                    right: 15px;
                                    width: 14px;
                                    height: 10px;
                                    pointer-events: none;
                                    background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                                }
                            }
                            & label {
                                color: $black;
                                font-weight: 500;
                                font-size: 16px;
                            }
                            & select {
                                color: black;
                                border: 1px solid #798387;
                                background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                                border-radius: 5px;
                                padding-right: 5px;
                            }
                            &.funnel {
                                width: 100%;
                            }
                        }
                    }
                    & .input_item {
                        padding: 5px 0;
                        width: 250px;

                        & .select_arrow {
                            position: relative;
                            & .arrow_down {
                                position: absolute;
                                top: 14px;
                                right: 15px;
                                width: 14px;
                                height: 10px;
                                pointer-events: none;
                                background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                            }
                        }
                        & label {
                            color: $black;
                            font-weight: 500;
                            font-size: 16px;
                        }
                        & input {
                            border: 1px solid #798387;
                            border-radius: 5px;
                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }
                        & select {
                            color: black;
                            border: 1px solid #798387;
                            background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                            border-radius: 5px;
                            padding-right: 5px;
                        }
                        input[type=number] {
                            -moz-appearance: textfield !important;
                        }
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none !important;
                            margin: 0 !important;
                        }
                    }
                }
                & .single_input {
                    & .input_item {
                        padding: 5px 0;
                        width: 100%;
                        & .select_arrow {
                            position: relative;
                            & .arrow_down {
                                position: absolute;
                                top: 14px;
                                right: 15px;
                                width: 14px;
                                height: 10px;
                                pointer-events: none;
                                background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                            }
                        }
                        & label {
                            color: $black;
                            font-weight: 500;
                            font-size: 16px;
                        }
                        & input {
                            border: 1px solid #798387;
                            border-radius: 5px;
                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }
                        & select {
                            color: black;
                            border: 1px solid #798387;
                            background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                            border-radius: 5px;
                            padding-right: 5px;
                        }
                    }
                }
            }
            & .done_btn-container {
                display: flex;
                justify-content: center;
                padding: 20px 0 10px;
                & .done_btn {
                    background: $light_blue;
                    box-shadow: 0 3px 6px #00000029;
                    border-radius: 5px;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $white;
                    font-weight: 400;
                    font-size: 18px;
                    border: none;
                }
            }
        }
        &_title {
            width: 100%;
            font-size: 25px;
            font-weight: 500;
            color: $main_blue;
            padding: 10px 0 5px;
            display: flex;
            justify-content: center;
            text-transform: uppercase;
        }
    }
.page {
    &_container {
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
        max-width: 1700px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        &.straight_length {
            max-width: 1600px;
            transition: .4s;
        }
    }
    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 5px;
        &-back {
            display: flex;
            align-items: center;
            color: $light_blue;
            cursor: pointer;
            & svg {
                margin-right: 8px;
            }
        }
    }
    &_content {
        display: flex;
        padding: 30px;
        & .lead_info {
            max-width: 500px;
            width: 100%;
            height: auto;
            border: 1px solid #B4BABC;
            border-radius: 5px;
            position: relative;
            &-edit {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;

            }
            &-head {
                display: flex;
                align-items: center;
                margin: 20px 15px;
                padding-bottom: 20px;
                border-bottom: 1px solid #B4BABC;
                &-img {
                    width: 90px;
                    height: 90px;

                    border-radius: 8px;
                    //background: url("~@/assets/images/user_avatar.svg");
                    background-image: url("~@/assets/images/profile_user.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                &-name {
                    color: $main_blue;
                    font-size: 25px;
                    font-weight: 500;
                    /*padding-bottom: 10px;*/
                }
            }
            &-data {
                padding: 0 20px;
                ::v-deep .item_status {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 225px;
                    height: 40px;
                    padding: 14px 0;
                    border-radius: 5px;
                    & select {
                        color: inherit;
                        background: #fff url("~@/assets/images/arrow_down.svg") right 0.75rem center/10px 10px no-repeat;
                        border-radius: 5px;
                        padding-right: 5px;
                        &:focus {
                            border: none;
                            box-shadow: none;
                        }
                    }
                }
            }
            &-reply {
                padding: 10px 0;
                span {
                    color: $light_blue;
                    margin-right: 60px;
                }
            }
            &-personal {
                margin: 10px 15px;
                border-bottom: 1px solid #B4BABC;
                padding-bottom: 20px;
                &.no_border {
                    border-bottom: none;
                }
                &-title {
                    padding: 10px 0 15px;
                    color: $main_blue;
                    font-weight: 500;
                    font-size: 20px;
                }
                & .info_item {
                    display: flex;
                    align-items: center;
                    padding: 2px 0;
                    &-key {
                        width: 130px;
                        color: $light_blue;
                    }
                    &-value {
                        display: flex;
                    }
                }
            }
        }
        & .lead_notes {
            display: flex;
            flex-direction: column;
            padding: 0 35px;
            width: 100%;
            &-container {
                border: 1px solid #B4BABC;
                border-radius: 5px;
                padding: 22px;
                & .card {
                    border: none;
                }
                ::v-deep .nav-link {
                    width: 112px;
                    height: 36px;
                    margin-right: 10px;
                    border-radius: 10px;
                    background: #E1E5E6 0% 0% no-repeat padding-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $main_blue;
                    font-weight: 500;
                    letter-spacing: 0;
                    padding: 0;
                    &.active {
                        background: $light_blue;
                        color: $white !important;
                    }
                }
                & .add_notes-content {
                    margin-top: 15px;
                    /*position: relative;*/
                    & textarea {
                        overflow: auto;
                        border: 1px solid #E1E5E6;
                        border-radius: 10px;
                        resize: none;
                        padding: 10px 20px;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    & .tab_bottom-btn {
                        width: 180px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $white;
                        background: $light_blue 0 0 no-repeat padding-box;
                        box-shadow: 0 3px 6px #00000029;
                        border-radius: 5px;
                        border: none;
                        &-container {
                            display: flex;
                            justify-content: flex-end;
                            padding: 24px 0 24px;
                            border-bottom: 1px solid #B4BABC;
                        }
                    }
                }
                & .send_email {
                    &-content {
                        margin-top: 15px;
                        & .tab_bottom-btn {
                            width: 180px;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $white;
                            background: $light_blue 0 0 no-repeat padding-box;
                            box-shadow: 0 3px 6px #00000029;
                            border-radius: 5px;
                            border: none;
                            &-container {
                                display: flex;
                                justify-content: space-between;
                                padding: 24px 0 10px;
                                & .input_item {
                                    padding: 5px 0;
                                    width: 240px;

                                    & .select_arrow {
                                        position: relative;

                                        & .arrow_down {
                                            position: absolute;
                                            top: 14px;
                                            right: 15px;
                                            width: 14px;
                                            height: 10px;
                                            pointer-events: none;
                                            background: url("~@/assets/images/arrow_down.svg") right no-repeat;
                                        }
                                    }

                                    & label {
                                        color: $black;
                                        font-weight: 500;
                                        font-size: 16px;

                                        & span {
                                            color: $grey;
                                        }
                                    }

                                    & input {
                                        border: 1px solid #798387;
                                        border-radius: 5px;

                                        &:focus {
                                            box-shadow: none;
                                            outline: none;
                                        }
                                    }

                                    & select {
                                        background: transparent;
                                        border: 1px solid #798387;
                                        border-radius: 5px;
                                        padding-right: 5px;
                                    }
                                }

                            }
                        }
                    }
                    &-info {
                        width: 100%;
                        min-height: 200px;
                        height: 100%;
                        border: 1px solid #B4BABC;
                        border-radius: 5px;
                        padding: 10px 20px;
                        & .subject_content {
                            width: 100%;
                            padding: 5px 0;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #E1E5E6;
                            & .subject_text {
                                color: $main_blue;
                                font-size: 16px;
                                font-weight: 500;
                                margin-right: 10px;
                            }
                            & input {
                                width: 100%;
                                height: 35px;
                                border: none;
                                &:focus-visible {
                                    outline: none;
                                }
                            }
                            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                                color: #B4BABC;
                                opacity: 1; /* Firefox */
                            }

                            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                                color: #B4BABC;
                            }

                            ::-ms-input-placeholder { /* Microsoft Edge */
                                color: #B4BABC;
                            }
                        }
                        & textarea {
                            overflow: auto;
                            border: none;
                            padding: 10px 0 0 0;
                            resize: none;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            &:focus {
                                outline: none !important;
                                border: none !important;
                                box-shadow: none !important;
                            }
                        }
                        & .file_attach {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding:  0;
                            display: flex;
                            justify-content: space-between;
                            height: 30px;
                            &-container {
                                width: 100%;
                                height: 2px;
                                position: relative;
                                ::v-deep label {
                                    background: transparent !important;
                                    border: 1px solid transparent;
                                    padding-top: 0;
                                    &:after {
                                        display: none !important;
                                    }
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        right: 5px;
                                        bottom: 8px;
                                        width: 25px;
                                        height: 30px;
                                        background: url("~@/assets/images/paperclip.svg") no-repeat center;
                                    }
                                }
                            }

                        }
                    }
                }
                & .send_text {
                    &-content {
                        margin-top: 15px;
                        & .tab_bottom-btn {
                            width: 180px;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $white;
                            background: $light_blue 0 0 no-repeat padding-box;
                            box-shadow: 0 3px 6px #00000029;
                            border-radius: 5px;
                            border: none;
                            &-container {
                                display: flex;
                                justify-content: space-between;
                                padding: 24px 0 10px;
                                & .input_item {
                                    padding: 5px 0;
                                    width: 240px;

                                    & .select_arrow {
                                        position: relative;

                                        & .arrow_down {
                                            position: absolute;
                                            top: 14px;
                                            right: 15px;
                                            width: 14px;
                                            height: 10px;
                                            pointer-events: none;
                                            background: url("~@/assets/images/arrow_down.svg") right no-repeat;
                                        }
                                    }

                                    & input {
                                        border: 1px solid #798387;
                                        border-radius: 5px;
                                        &:focus {
                                            box-shadow: none;
                                            outline: none;
                                        }
                                    }
                                    & select {
                                        background: transparent;
                                        border: 1px solid #798387;
                                        border-radius: 5px;
                                        padding-right: 5px;
                                    }
                                    & textarea {
                                        overflow: auto;
                                        border: 1px solid #798387;
                                        border-radius: 5px;

                                        &::-webkit-scrollbar {
                                            display: none;
                                        }
                                    }
                                }

                            }
                        }
                    }
                    &-info {
                        width: 100%;
                        min-height: 200px;
                        height: 100%;
                        border: 1px solid #B4BABC;
                        border-radius: 5px;
                        padding: 10px 20px;
                        & textarea {
                            overflow: auto;
                            border: none;
                            padding: 10px 0 0 0;
                            resize: none;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            &:focus {
                                outline: none !important;
                                border: none !important;
                                box-shadow: none !important;
                            }
                        }

                    }
                }
            }
        }
        & .lead_history-container {
            & .empty_data {
                color: $grey;
                font-weight: 500;
                font-size: 20px;
            }
            & .history {
                &_title {
                    padding: 25px 0;
                    font-weight: 500;
                    font-size: 20px;
                    color: $main_blue;
                }
                &_content {
                    width: 100%;
                    border: 1px solid #B4BABC;
                    border-radius: 5px;
                }
                &_item {
                    display: flex;
                    justify-content: space-between;
                    padding: 12px 8px;
                    margin: 2px 4px;
                    &:not(:last-child) {
                        border-bottom: 1px solid #E1E5E6;
                    }
                    &-content {
                        display: flex;
                    }
                }
                &_time-box {
                    width: 82px;
                    height: 82px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background: #EBF2F5 0 0 no-repeat padding-box;
                    border-radius: 5px;
                    color: $main_blue;
                    &_date {
                        font-weight: 500;
                    }
                }
                &_text {
                    padding: 10px 30px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction:  column;
                    &-title {
                        font-weight: 500;
                        font-size: 18px;
                        color: $main_blue;
                        line-height: 26px;
                    }
                    &-txt {
                        font-weight: 400;
                    }
                }
                &_like {
                    margin-right: 15px;
                    cursor: pointer;
                    width: 18px;
                    height: 18px;
                    background: url("~@/assets/images/heart.svg") no-repeat center;
                    background-size: contain;
                    &.full_heart {
                        background: url("~@/assets/images/heart_full.svg") no-repeat center;
                        background-size: contain;
                    }
                }
            }


        }

    }
}
::v-deep .modal-content {
    border: none !important;
    width: 600px;
    ::v-deep .modal-body {
        width: 580px !important;
    }
}
    ::v-deep .custom-select {
        border: none;
    }


    ::v-deep .select_arrow {
        .multiselect__tags {
            .multiselect__placeholder {
                padding: 7px 0 6px 10px;
                margin-bottom: 0;
            }

            .multiselect__input {
                padding-left: 10px !important;
            }
        }
    }

    ::v-deep .multiselect {
        width: 100%;
        height: 38px;
        min-height: 38px;
        background: transparent 0 0 no-repeat padding-box;
        cursor: pointer;
        border: 1px solid #798387;
        border-radius: 5px;
    }

    ::v-deep .multiselect__tags {
        background: transparent;
        padding-left: 0;
        min-height: 38px;
        padding-top: 0;
    }

    ::v-deep .multiselect__tag {
        background: transparent;
        color: $black;
        margin-top: 6px;
    }

    ::v-deep .multiselect__tag-icon:after {
        color: red;
    }

    ::v-deep .multiselect__tag-icon:hover {
        background: transparent;
    }

    ::v-deep .multiselect__option--highlight,
    ::v-deep .multiselect__option--highlight:after {
        background: $blue_grey;
        color: $black;
    }

    ::v-deep .multiselect__select:before {
        border-width: 0;
        border-style: none;
    }

    ::v-deep .multiselect__input {
        margin: 0;
        padding: 0;
    }



</style>